import styled from 'styled-components';
import iItem from '../../types/Items/iItem';
import { Col, Image, Row } from 'react-bootstrap';
import ItemHelper from '../../helpers/ItemHelper';

const Wrapper = styled.div`
  .item-row {
    padding: 1rem;
    
    &:hover {
      background-color: #dcdcdc;
    }
    
    .cover-img {
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
    
    .meta-row,
    .description {
      font-size: 11px;
    }
  }
`;

type iItemList = {
  items: iItem[];
}
const ItemList = ({items}: iItemList) => {
  return (
    <Wrapper>
      {items.map(item => {
        return (
          <Row className={'item-row'} key={item.id}>
            <Col md={2} className={'cover-img'}>
              <Image src={ItemHelper.getItemCoverImgUrl(item)} />
            </Col>
            <Col>
                <h6>{item.title}</h6>
                <div className={'ellipsis description'} dangerouslySetInnerHTML={{__html: item.description || '&nbsp;'}}/>
                <Row className={'meta-row'}>
                  <Col md={4}>
                    <b>ISBN: </b> {item.isbn}
                  </Col>
                  <Col md={4}>
                    <b>作者: </b> {item.author}
                  </Col>
                  <Col md={4}>
                    <b>出版社: </b> {item.publisher}
                  </Col>
                  <Col md={4}>
                    <b>翻译人: </b> {item.translator}
                  </Col>
                  <Col md={4}>
                    <b>数量: </b> {(item.Instances || []).length}
                  </Col>
                </Row>
            </Col>
          </Row>
        )
      })}
    </Wrapper>
  )
}

export default ItemList;
