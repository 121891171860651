import FrontPage from '../../layouts/FrontPage';
import ItemListPanel from '../../components/Items/ItemListPanel';

const HomePage = () => {
  return (
    <FrontPage>
      <ItemListPanel />
    </FrontPage>
  )
}

export default HomePage;
