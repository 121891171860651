import CategorySelector from './CategorySelector';
import { useEffect, useState } from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import LoadingBtn from '../common/LoadingBtn';
import * as Icons from 'react-bootstrap-icons';
import styled from 'styled-components';
import iItem from '../../types/Items/iItem';
import ItemService from '../../services/Items/ItemService';
import Toaster from '../common/Toaster';
import { OP_LIKE, OP_OR } from '../../helpers/ServiceHelper';
import ItemList from './ItemList';
import iPaginatedResult from '../../types/iPaginatedResult';
import MathHelper from '../../helpers/MathHelper';
import PageLoadingSpinner from '../common/PageLoadingSpinner';

const Wrapper = styled.div`
  .search-panel {
    .col,
    [class^='col-'] {
      padding-right: 0px;
    }
  }
  .list-wrapper {
    margin-top: 2rem;
  }
`;
type iSearchCriteria = {
  searchTxt?: string;
  categoryIds?: string[];
}
const ItemListPanel = () => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState<iSearchCriteria>({})
  const [searchingCriteria, setSearchingCriteria] = useState<iSearchCriteria>({})
  const [items, setItems] = useState<iPaginatedResult<iItem> | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)

  useEffect(() => {
    let isCanceled = false;
    const searchTxt = `${searchingCriteria.searchTxt || ''}`.trim();
    const whereObj = {
      isActive: true,
      ...(searchTxt === '' ? {} : {
        [OP_OR]: [
          {title: {[OP_LIKE]: `%${searchTxt}%`}},
          {publisher: {[OP_LIKE]: `%${searchTxt}%`}},
          {isbn: {[OP_LIKE]: `%${searchTxt}%`}},
          {translator: {[OP_LIKE]: `%${searchTxt}%`}},
          {author: {[OP_LIKE]: `%${searchTxt}%`}},
        ]
      }),
      ...((searchingCriteria.categoryIds || []).length <= 0 ? {} : {
        categoryIds: searchingCriteria.categoryIds
      }),
    }

    setIsSearching(true);
    ItemService.getAll({
        where: JSON.stringify(whereObj),
        perPage: 12,
        currentPage,
        include: 'CoverImg,Instances'
      }).then(resp => {
        if (isCanceled) { return }
        setItems({
          ...resp,
          data: (resp.currentPage || 1) <= 1 ? (resp.data || []) : [...(items?.data || []), ...(resp.data || [])],
        });
      }).catch(err => {
        if (isCanceled) { return }
        Toaster.showApiError(err);
      }).finally(() => {
        if (isCanceled) { return }
        setIsSearching(false);
      })

    return () => {
      isCanceled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchingCriteria, count]);

  const getListPanel = () => {
    if (isSearching && (currentPage) <= 1) {
      return <PageLoadingSpinner />
    }
    return <ItemList items={items?.data || []} />
  }

  const getLoadMoreBtn = () => {
    if ((items?.currentPage || 1) >= (items?.pages || 1)) {
      return null;
    }

    return <div className={'load-more-wrapper text-center'}>
      <LoadingBtn
        onClick={() => setCurrentPage(MathHelper.add(items?.currentPage || 1, 1))}
        isLoading={isSearching === true && (items?.currentPage || 1) >= 1}>
        <Icons.ArrowDown /> Load More
      </LoadingBtn>
    </div>
  }

  return (
    <Wrapper>
      <h5>借阅</h5>
      <Row className={'search-panel'}>
        <Col>
          <FormControl
            value={searchCriteria.searchTxt}
            onChange={event => setSearchCriteria({...searchCriteria, searchTxt: event.target.value})}
            placeholder={'可查询: 名字、作者、出版社、翻译人、代码或者ISBN'}
          />
        </Col>
        <Col md={4}>
          <CategorySelector
            values={searchCriteria.categoryIds}
            isMulti
            onSelect={(values) => {
              if (values === null) {
                delete searchCriteria.categoryIds;
                setSearchCriteria(searchCriteria);
                return;
              }

              if (!(Array.isArray(values))) {
                return;
              }
              setSearchCriteria({
                ...searchCriteria,
                categoryIds: values.map(value => `${value.value}`),
              });
            }}
          />
        </Col>
        <Col md={2} className={'text-right'}>
          <LoadingBtn isLoading={isSearching} onClick={() => {
            setSearchingCriteria(searchCriteria);
            setCount(MathHelper.add(count, 1));
            setCurrentPage(1);
          }}>
            <Icons.Search /> {' '} 查询
          </LoadingBtn>
        </Col>
      </Row>

      <Row className={'list-wrapper'}>
        <Col>
          {getListPanel()}
          {getLoadMoreBtn()}
        </Col>
      </Row>
    </Wrapper>
  )
}

export default ItemListPanel;
