import SelectBox from '../common/SelectBox';
import { iAutoCompleteSingle } from '../common/AutoComplete';
import {useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import Toaster from '../common/Toaster';
import iCategory from '../../types/Items/iCategory';
import CategoryService from '../../services/Items/CategoryService';


type iCategorySelector = {
  values?: iAutoCompleteSingle[] | string[];
  onSelect?: (iCategory: iAutoCompleteSingle | iAutoCompleteSingle[] | null) => void;
  allowClear?: boolean;
  showIndicator?: boolean;
  isMulti?: boolean;
  classname?: string;
}

const translateToOption = (category: iCategory) => {
  return {value: category.id, data: category, label: category.name}
}

const CategorySelector = ({values, onSelect, allowClear, classname, showIndicator = true, isMulti = false}: iCategorySelector) => {
  const [optionsMap, setOptionsMap] = useState<{[key: string]: iAutoCompleteSingle}>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    CategoryService.getAll({
      where: JSON.stringify({isActive: true}),
      perPage: 9999999,
      sort: 'name:ASC'
    })
      .then(resp => {
        if (isCancelled === true) { return }

        const optMap = (resp.data || [])
          .reduce((map, category) => {
            return {
              ...map,
              [category.id]: translateToOption(category),
            };
          }, {});

        setOptionsMap(optMap);
      })
      .catch(err => {
        if (isCancelled === true) { return }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCancelled === true) { return }
        setIsLoading(false);
      })
    return () => {
      isCancelled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOptions = () => {
    return Object.values(optionsMap)
      .sort((opt1, opt2) => {
        if (!opt1.data || !opt2.data) {
          return 1;
        }
        return opt1.data.Code > opt2.data.Code ? 1 : -1;
      })
  }

  const getSelectedValues = () => {
    if (!values) {
      return null;
    }
    if (values?.length <= 0) {
      return [];
    }
    return values.map(value => {
      if(typeof value === 'string') {
        return (value in optionsMap ? optionsMap[value] : {value, label: value, data: null})
      }
      return value;
    })
  }

  if (isLoading === true) {
    return <Spinner animation={'border'} size={'sm'}/>;
  }

  return (
    <SelectBox
      className={classname}
      options={getOptions()}
      isMulti={isMulti}
      onChange={onSelect}
      value={getSelectedValues()}
      isClearable={allowClear}
      showDropdownIndicator={showIndicator}
    />
  )
}

export default CategorySelector;
