import React from 'react';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';
import Logo from '../components/Logo';

const Wrapper = styled.div`
  padding: 1rem;
  text-align: center;
  .logo {
    margin: auto;
    padding: 1rem;
    img {
      max-width: 350px;
      min-width: 180px;
      width: 50%;
      height: auto;
    }
  }
`;

const reloadPage = () => {
  window.location.reload();
};

type iPageNotFound = {
  title?: string | React.ReactElement;
  description?: string | React.ReactElement;
  secondaryBtn?: React.ReactElement;
  primaryBtn?: React.ReactElement
}
const PageNotFound = ({title, description, primaryBtn, secondaryBtn}: iPageNotFound) => {
  return <Wrapper>
    <div className={'logo'}>
      <Logo />
    </div>
    <div className={'description'}>
      <h4 className={'title'}>{title || 'Requested page can\'t be found.'}</h4>
      <p>
        {description || `This page might have been removed or temporarily unavailable.`}
      </p>
    </div>
    <div className={'actions'}>
      { primaryBtn || <Button variant={'secondary'} size="sm" onClick={() => reloadPage()}>Reload Page</Button>}
      { secondaryBtn }
    </div>
  </Wrapper>
};

export default PageNotFound;
