import iItem from '../types/Items/iItem';

const getItemCoverImgUrl = (item: iItem) => {
  const coverImgUrl = `${item.CoverImg?.url || ''}`.trim();
  if (coverImgUrl === '') {
    return `${process.env.REACT_APP_URL || ''}/images/DefaultItemCover.png`;
  }
  return coverImgUrl
}

const ItemHelper = {
  getItemCoverImgUrl
}

export default ItemHelper;
