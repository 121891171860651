// eslint-disable-next-line import/extensions
import AppService, { iConfigParams } from '../AppService';
import iCategory from '../../types/Items/iCategory';
import iPaginatedResult from '../../types/iPaginatedResult';

const endPoint = '/category';
const getAll = (params?: iConfigParams, config?: iConfigParams): Promise<iPaginatedResult<iCategory>> => {
  return AppService.get(`${endPoint}`, params || {}, config).then((resp) => resp.data);
};

const CategoryService = {
  getAll,
};

export default CategoryService;
