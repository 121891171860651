import Logo from '../components/Logo';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { FlexContainer } from '../styles/AppWrapper';

const Wrapper = styled.div`
  .header-wrapper {
    .logo {
      img {
        max-width: 280px;
      }
    }
  }
  
  .content-wrapper {
    min-height: calc(100vh - 9.5rem);
  }
  
  .footer-wrapper {
    //background-color: #ececec;
    padding: 1rem;
  }
`;

type iFrontPage = {
  header?: any;
  footer?: any;
  children?: any;
}
const FrontPage = ({header, footer, children}: iFrontPage) => {

  const getHeader = () => {
    if (header) {
      return header;
    }
    return (
      <FlexContainer className={'header-wrapper justify-content-between space-below'}>
        <div className={'logo'}>
          <a href={process.env.REACT_APP_MAIN_SITE_URL || ''}><Logo /></a>
        </div>
      </FlexContainer>
    )
  }

  const getFooter = () => {
    if (footer) {
      return footer;
    }
    return (
      <FlexContainer className={'footer-wrapper justify-content-center space-above text-center'}>
        <div>
          Since 2017 | Powered by Web Library System | Developed by <a href={'https://sysbox.com.au'}>Sysbox Pty Ltd</a>
        </div>
      </FlexContainer>
    )
  }

  return (
    <Wrapper>
      <Container>
        {getHeader()}
        <div className={'content-wrapper'}>
          {children}
        </div>
        {getFooter()}
      </Container>
    </Wrapper>
  )
};

export default FrontPage;
