// eslint-disable-next-line import/extensions
import AppService, { iConfigParams } from '../AppService';

const endPoint = '/item';
const getAll = (params?: iConfigParams, config?: iConfigParams) => {
  return AppService.get(`${endPoint}`, params || {}, config).then((resp) => resp.data);
};

const ItemService = {
  getAll,
};

export default ItemService;
