const getTokenName = () => process.env.REACT_APP_LOCAL_USER_TOKEN_NAME || 'token';

const getToken = () => localStorage.getItem(getTokenName());

const setToken = (newToken: string) => localStorage.setItem(getTokenName(), newToken);

const removeToken = () => localStorage.removeItem(getTokenName());

const getItem = (name: string) => {
  const value = localStorage.getItem(name);
  try {
    return JSON.parse(`${value || ''}`);
  } catch {
    return value;
  }
};

const setItem = (name: string, value: any) => {
  try {
    return localStorage.setItem(name, JSON.stringify(value));
  } catch {
    return localStorage.setItem(name, value);
  }
};

const removeItem = (name: string) => localStorage.removeItem(name);

const LocalStorageService = {
  getToken,
  setToken,
  removeToken,

  setItem,
  getItem,
  removeItem,
};

export default LocalStorageService;
