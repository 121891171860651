import ReactDOM from "react-dom";
import React, {ReactComponentElement} from "react";
import { ToastContainer } from 'react-toastify';
import ItemListPanel from './components/Items/ItemListPanel';

const load = (query: string, component: ReactComponentElement<any>) => {
  const roots = document.querySelectorAll(query) || [];
  // console.log('roots', roots);
  for (let i = 0; i < roots.length; i++) {
    ReactDOM.render(component, roots[i]);
  }
};

const loadAll = () => {
  load(
    '.lib-app-loader-item-list',
    // @ts-ignore
    <React.StrictMode>
      <ItemListPanel/>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.StrictMode>
  );
}

const LoadComponents = {
  load,
  loadAll,
}

export default LoadComponents;
