import axios, { AxiosRequestConfig } from 'axios';
// eslint-disable-next-line import/no-unresolved,import/extensions
import LocalStorageService from './LocalStorageService';

export const HEADER_NAME_APP_TOKEN = 'X-LIB-APP-TOKEN';
export const HEADER_NAME_SELECTING_FIELDS = 'X-LIB-SELECT-FIELDS';

export type iConfigParams = {
  [key: string]: any;
};
export type iParams = {
  [key: string]: string | boolean | number | null | undefined | string[];
};

const cancelToken = axios.CancelToken;

const getEndPointUrl = (url: string) => `${process.env.REACT_APP_API_END_POINT}${url}`;

const getHeaders = (extra = {}) => {
  const token = LocalStorageService.getToken();
  const authHeader = (!token || token === '') ? {} : { Authorization: `Bearer ${token}` };
  return {
    headers: {
      [HEADER_NAME_APP_TOKEN]: `${process.env.REACT_APP_TOKEN || ''}`,
      ...authHeader,
      ...extra,
    },
  };
};

const getUrlParams = (params: iConfigParams = {}) => {
  const paramString = typeof params === 'object' && Object.keys(params).length > 0
    ? new URLSearchParams(params).toString()
    : '';
  return paramString === '' ? '' : `?${paramString}`;
};

const get = (url: string, params: iConfigParams = {}, config: AxiosRequestConfig = {}) => {
  const { headers, ...rest } = config;
  return axios.get(
    `${getEndPointUrl(url)}${getUrlParams(params)}`,

    {
      ...rest,
      ...getHeaders(headers),
    },
  );
};

// eslint-disable-next-line max-len
const post = (url: string, params: iParams, config: AxiosRequestConfig = {}) => axios.post(getEndPointUrl(url), params, {
  ...config,
  ...getHeaders(),
});
// eslint-disable-next-line max-len
const put = (url: string, params: iConfigParams, config: AxiosRequestConfig = {}) => axios.put(getEndPointUrl(url), params, {
  ...config,
  ...getHeaders(),
});
// eslint-disable-next-line max-len
const remove = (url: string, params: iConfigParams = {}, config: AxiosRequestConfig = {}) => axios.delete(
  `${getEndPointUrl(url)}${getUrlParams(params)}`,
  {
    ...config,
    ...getHeaders(),
  },
);
const getUploadHeaders = (extra = {}) => getHeaders({
  'Content-Type': 'multipart/form-data',
  ...extra,
});
// eslint-disable-next-line max-len
const uploadImage = (url: string, params: FormData, config: AxiosRequestConfig = {}) => axios.post(getEndPointUrl(url), params, getUploadHeaders(config));

const cancelAll = () => cancelToken.source().cancel();

const AppService = {
  get,
  post,
  put,
  delete: remove,
  uploadImage,
  cancelAll,
};

export default AppService;
